<template>
  <el-row class="header">
    <!-- 左 -->
    <el-col
      :span="11"
      class="doc"
    >
      <img
        style="height:60px;"
        src="../../../assets/logo.png"
      >
      <div
        style="padding-left:80px;"
        @click="isShowDocumentDialog=true"
      >
        {{ $t('value_map.indicatorDef') }}
      </div>
    </el-col>
    <!-- 中 -->
    <!-- <el-col
      :span="6"
      class="title"
    > -->
    <!-- <el-row>
        <el-col
          :span="14"
          style="text-align:center;"
        >
          {{ $t('value_map2.dealerValueMap') }}
        </el-col>
        <el-col
          :span="10"
          class="doc"
        >
          <div
            @click="isShowDocumentDialog=true"
          >
            {{ $t('value_map.indicatorDef') }}
          </div>
        </el-col>
      </el-row> -->
    <!-- </el-col> -->
    <!-- 右 -->
    <el-col :span="13">
      <el-row
        :gutter="20"
        class="info-row"
      >
        <el-col :span="6">
          <div class="label">
            {{ $t('value_map.groupName') }}
          </div>
        </el-col>
        <el-col
          :span="6"
        >
          <el-select
            v-model="params.groupName"
            style="width:100%"
            filterable
            remote
            :remote-method="getGroupList"
            value-key="id"
            :clearable="true"
            :loading="groupLoading"
            placeholder
            @change="groupCodeChangeHandler"
            @focus="getGroupList"
          >
            <i
              slot="prefix"
              class="el-icon-search"
              style="line-height: 28px;"
            />
            <el-option
              v-for="item in groupList"
              :key="item.key"
              size="mini"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="6">
          <div class="label">
            {{ $t('value_map.contrastType') }}
          </div>
        </el-col>
        <el-col
          :span="6"
        >
          <el-select
            v-model="params.contrastType"
            style="width:100%"
            :placeholder="$t('validate.notBlank')"
            @change="$parent.$parent.$parent.loadData(params)"
          >
            <el-option
              v-for="item in $getDictList('contrast_type_group')"
              :key="item.value"
              size="mini"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        class="info-row"
      >
        <el-col :span="6">
          <div class="label">
            {{ $t('value_map.reportPeriod') }}
          </div>
        </el-col>
        <el-col :span="12">
          <el-date-picker
            v-model="params.period"
            type="month"
            style="width:100%;"
            :clearable="false"
            value-format="yyyyMM"
            :picker-options="pickerOptions"
            @change="$parent.$parent.$parent.loadData(params)"
          />
        </el-col>
        <el-col
          :span="6"
        >
          <el-select
            v-model="params.calculateType"
            style="width:100%"
            :placeholder="$t('validate.notBlank')"
            @change="$parent.$parent.$parent.loadData(params)"
          >
            <el-option
              v-for="item in calculateTypeList"
              :key="item.value"
              size="mini"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        class="info-row"
      >
        <el-col :span="6">
          <div class="label">
            {{ $t('value_map2.contrastGroupCount') }}
          </div>
        </el-col>
        <el-col
          :span="6"
        >
          <el-button style="width:100%;height:28px;">
            {{ baseInfo.contrastGroupCount }}
          </el-button>
        </el-col>
      </el-row>
    </el-col>
    <document :show-flag.sync="isShowDocumentDialog" />
  </el-row>
</template>

<script>
import document from './document'
const BASEURL = {
  office: '/system/office/list'
}
export default {
  components: { document },
  props: {
    baseInfo: { type: Object },
    params: { type: Object }
  },
  data () {
    return {
      groupList: [],
      groupLoading: false,
      isShowDocumentDialog: false,
      pickerOptions: {
        disabledDate (time) {
          return (time.getMonth() < 12 && time.getFullYear() === 2019) || (time.getFullYear() < 2019)
        }
      }
    }
  },
  computed: {
    calculateTypeList () {
      let list = this.$getDictList('calculate_type').filter(item => { return item.label !== 'QTD' })
      if (this.params.period) {
        let date = this.$moment(this.params.period, 'YYYYMM')
        let mon = new Date(date).getMonth()
        const isQuarter = (mon + 1) % 3 === 0
        if (isQuarter) list = this.$getDictList('calculate_type')
      }
      return list
    }
  },
  methods: {
    getGroupList (name) {
      this.groupLoading = true
      const params = {
        filter: [
          { left: 'category', operate: '=', right: 'sys_office_category_2' },
          { left: 'parentId', operate: '=', right: 'zjc' }
        ]
      }
      if (typeof name === 'string') {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'nameEn', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.groupList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.groupLoading = false
        })
    },
    groupCodeChangeHandler (obj) {
      this.params.groupId = obj.id
      this.params.groupName = obj.name
      this.$parent.$parent.$parent.loadData(this.params)
    }
  }
}
</script>

<style lang="less" scoped>
.doc {
  font-size: 16px;
  text-decoration: underline;
  color: #3573b4;
  font-weight: 550;
  cursor: pointer;
  :hover {
    color: #27598e;
  }
}
.title {
  font-weight: 600;
  line-height: 60px;
  font-size: 24px;
}
.info-row {
  height: 28px;
  margin: 6px 0;
}
.label {
  background: #f5e2cb;
  line-height: 28px;
  padding: 0 4px;
  text-align: center;
}
</style>
