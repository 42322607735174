<template>
  <div
    :style="{height: height || '60px', 'overflow-y': (num > 6 ? 'scroll' : 'hidden')}"
    class="wrapper"
  >
    <div class="title">
      指标详情
    </div>
    <div
      v-if="!dataList.length"
      class="tip"
    >
      暂无数据
    </div>
    <div
      v-for="(ele, index) in dataList"
      :key="index"
      class="card"
    >
      <div
        v-if="index===0"
        class="card__title"
      >
        {{ title }}
      </div>
      <div class="card__body">
        <div style="width:100%;">
          <div class="firstRow">
            <div
              class="light"
              :style="{backgroundColor: ele.level === 'A' ? 'green' : (ele.level === 'B' ? 'orange' : (ele.level === 'C' ? 'pink' : (ele.level === 'D' ? 'red' : '#bbb')))}"
            />
            {{ ele.rank || 0 }}
          </div>
          <div class="secondRow">
            {{ ele.name }}
          </div>
          <div class="thirdRow">
            <table style="width:100%;">
              <td>当期</td><td>上期</td><td>标杆</td>
            </table>
          </div>
          <div class="fourthRow">
            <table style="width:100%;">
              <td style="width:33%;">
                {{ ele.kpiValue || '' }}
              </td>
              <td style="width:33%;">
                {{ ele.previousKpiValue || '' }}
              </td>
              <td style="width:33%;">
                {{ ele.standard || '' }}
              </td>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    num: Number,
    title: String,
    height: String,
    dataList: { type: Array }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  border: 1px solid #ccc;
  margin: 166px 0 0 6px;
  overflow-x: hidden;
  width: 180px;
  .title {
    width: 100%;
    height: 26px;
    font-size: 11px;
    background: #999;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
  }
  .tip {
    color: #aaa;
    font-size: 10px;
    line-height: 36px;
    text-align: center;
  }
  .card {
    width: 138px;
    margin: 5px 16px 0 20px;
    .card__title {
      width: 100%;
      text-align: center;
      background: #ddd;
      height: 22px;
      line-height: 22px;
      font-weight: 600;
    }
    .card__body {
      display: flex;
      justify-content: space-around;
      .firstRow {
        height: 22px;
        line-height: 22px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .light {
          width: 10px;
          height: 10px;
          border-radius: 10px;
        }
      }
      .secondRow {
        background: #bbb;
        text-align: center;
        height: 22px;
        line-height: 22px;
      }
      .thirdRow {
        background: #eee;
        width: 100%;
        text-align: center;
        height: 22px;
        line-height: 22px;
      }
      .fourthRow {
        width: 100;
        text-align: center;
        height: 22px;
        line-height: 22px;
        border: 1px solid #eee;
      }
    }
  }
}
</style>
