<template>
  <div>
    <el-row class="title">
      {{ $t('value_map2.dealerBaseInfo') }}
    </el-row>
    <el-row
      :gutter="20"
      class="info-row"
    >
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map2.groupName') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.name || '' }}
        </el-button>
      </el-col>
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map2.groupDealerCount') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.groupDealerCount || '' }}
        </el-button>
      </el-col>
      <el-col :span="4">
        <div class="label">
          {{ $t('value_map2.operationSize') }}
        </div>
      </el-col>
      <el-col
        :span="4"
      >
        <el-button class="info-items">
          {{ baseInfo.operationSize || '' }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    baseInfo: { type: Object }
  },
  data () {
    return {}
  },
  computed: {
    areaData () {
      return this.$store.state.app.sysArea
    }
  },
  methods: {
    translateProvince (val) {
      if (!val) return ''
      const rs = this.areaData.find(data => {
        return data.id === val
      })
      return rs ? rs.name : ''
    },
    translateCity (val) {
      if (!val || !this.baseInfo.provinceId) return ''
      const provinceId = this.baseInfo.provinceId
      const province = this.areaData.find(data => {
        return data.id === provinceId
      })
      if (province) {
        const rs = province.children.find(data => {
          return data.id === val
        })
        return rs ? rs.name : ''
      } else return ''
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  background: #eee;
  padding: 6px;
  font-size: 12px;
  font-weight: 600;
}
.info-row {
  height: 28px;
  margin: 6px 0;
  .info-items {
    width: 100%;
    height: 28px;
  }
}
.label {
  background: #f5e2cb;
  line-height: 28px;
  padding: 0 4px;
  text-align: center;
}
</style>
